<template>
  <v-navigation-drawer
    v-model="toggleSidebar"
    :clipped="$vuetify.breakpoint.mdAndUp"
    hide-overlay
    app
    :width="250"
    color="primary"
  >
    <v-list class="pt-0" dense>
      <template v-for="item in list">
        <v-list-item color="white" :key="item.label" :to="item.route">
          <v-list-item-action>
            <v-icon v-if="item.icon" size="22px">{{ item.icon }}</v-icon>
            <v-img
              v-else-if="item.img"
              :width="item.width ? item.width : 25"
              :src="item.img"
            />
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title style="color: white !important">
              {{ item.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Sidebar",

  data() {
    return {};
  },

  computed: {
    list() {
      return [
        {
          img: require("@/assets/icons/dashboard.svg"),
          route: { name: "Home" },
          label: this.$t("common.sidebar.dashboard"),
        },
        {
          img: require("@/assets/icons/brand.png"),
          route: { name: "Accounts" },
          label: this.$t("common.sidebar.accounts"),
        },
        {
          img: require("@/assets/icons/factory.png"),
          route: { name: "Suppliers" },
          label: this.$t("common.sidebar.suppliers"),
        },
        {
          img: require("@/assets/icons/project-impact.png"),
          route: { name: "ProjectsImpact" },
          label: this.$t("common.sidebar.projects-impact"),
        },
        // {img: require("@/assets/icons/study.png"), route: {name: 'Studies'}, label: this.$t('common.sidebar.studies')},
        {
          img: require("@/assets/icons/collection.png"),
          route: { name: "Collections" },
          label: this.$t("common.sidebar.collections"),
        },
        {
          img: require("@/assets/icons/product.png"),
          route: { name: "Studies" },
          label: this.$t("common.sidebar.studies"),
        },
        {
          img: require("@/assets/icons/component.png"),
          route: { name: "Components" },
          label: this.$t("common.sidebar.components"),
        },
        {
          img: require("@/assets/icons/material.png"),
          route: { name: "Materials" },
          label: this.$t("common.sidebar.materials"),
        },
        {
          img: require("@/assets/icons/qr-code.png"),
          route: { name: "Links" },
          label: this.$t("common.sidebar.links"),
        },
      ];
    },

    toggleSidebar: {
      get() {
        return this.$store.getters["getToggleSidebar"];
      },
      set(val) {
        this.$store.commit("setToggleSidebar", val);
      },
    },
  },
};
</script>

<style>
.v-list-item--disabled {
  color: rgba(0, 0, 0, 0.2) !important;
}
</style>
